:root{
    scroll-behavior: auto;
    --blue: #2a3285;
    --blue-rgb: 0, 79, 127;
    --light-blue: #32397d;
    --light-blue-rgb: 112, 118, 185;
    --orange-rgb: 253, 126, 20; 
    --pink-rgb: 214, 51, 132;
}
body {
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: rgba(var(--light-blue-rgb), 0.1);
    background-color: #eee;
    background-attachment: fixed;
}
.l{box-shadow: inset 0 0 0 1px red;}
::-webkit-scrollbar{width:4px;height:4px;background: transparent;}
::-webkit-scrollbar-track{border-radius:10px}
::-webkit-scrollbar-thumb{
    border-radius:10px;background-color: rgba($color: #56b6e7, $alpha: 0.5);
    &:hover{
        background-color: var(--blue);
    }
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    box-shadow: 0 0 0 0px transparent inset !important;
    transition: background-color 600000s 0s, color 600000s 0s;
}
input[data-autocompleted] {background-color: transparent !important;}

.icon{
    stroke: unset;
    stroke-width: 0px;
    fill: currentColor;
    display: inline-block;
    width: 1em;
    height: 1em;
    vertical-align: -0.15em;
}
.icon-h16{height: 16px!important} .icon-h17{height: 17px!important}
.icon-h18{height: 18px!important} .icon-h19{height: 19px!important}
.tscale-0p6{transform: scale(0.6)} .tscale-0p65{transform: scale(0.65)} 
.tscale-0p7{transform: scale(0.7)} .tscale-0p75{transform: scale(0.75)}
.tscale-0p8{transform: scale(0.8)} .tscale-0p85{transform: scale(0.85)} 
.tscale-0p9{transform: scale(0.9)} .tscale-0p95{transform: scale(0.95)}
.tscale-1{transform: scale(1)} .tscale-1p1{transform: scale(1.1)}
.tscale-1p2{transform: scale(1.2)} .tscale-1p3{transform: scale(1.3)}
.tscale-1p4{transform: scale(1.4)} .tscale-1p5{transform: scale(1.5)}
.tscale-1p6{transform: scale(1.6)} .tscale-1p7{transform: scale(1.7)}
.tscale-1p8{transform: scale(1.8)} .tscale-1p9{transform: scale(1.9)}
.border-light{
    border: 1px solid rgba(0,0,0,0.06)!important;
}
.fw-medium{font-weight: 500;}

.opacity-5{opacity: 0.05} .opacity-10{opacity: 0.10}
.opacity-15{opacity: 0.15} .opacity-20{opacity: 0.20}
.opacity-80{opacity: 0.80} .opacity-85{opacity: 0.85}

.rounded-10{border-radius: 10px} .rounded-15{border-radius: 15px}
.rounded-20{border-radius: 20px} .rounded-25{border-radius: 25px}

.mmw-60{min-width: 60px; max-width: 60px} .mmw-70{min-width: 70px; max-width: 70px}
.mmw-80{min-width: 80px; max-width: 80px} .mmw-100{min-width: 100px; max-width: 100px}

.minw-auto{min-width: auto} .minw-50{min-width: 50px}
.minw-100{min-width: 100px} .minw-110{min-width: 110px}
.minw-115{min-width: 115px} .minw-120{min-width: 120px}
.minw-125{min-width: 125px} .minw-130{min-width: 130px}
.minw-150{min-width: 150px} .minw-200{min-width: 200px}
.minw-280{min-width: 280px} .minw-300{min-width: 300px}

.minh-40{min-height: 40px} .minh-45{min-height: 45px}
.minh-50{min-height: 50px} .minh-55{min-height: 55px}

.wh-40{width: 40px; height: 40px} .wh-45{width: 45px; height: 45px}
.wh-50{width: 50px; height: 50px} .wh-60{width: 60px; height: 60px}

.maxw-35{max-width: 35px} .maxw-40{max-width: 40px}
.maxw-45{max-width: 45px} .maxw-50{max-width: 50px}
.maxw-55{max-width: 55px} .maxw-60{max-width: 60px}
.maxw-70{max-width: 70px} .maxw-80{max-width: 80px}
.maxw-100{max-width: 100px} .maxw-120{max-width: 120px}
.maxw-140{max-width: 140px} .maxw-160{max-width: 160px}
.maxw-180{max-width: 180px} .maxw-280{max-width: 280px}
.maxw-300{max-width: 300px} .maxw-320{max-width: 320px}

.border-orange{border-color: var(--bs-orange)!important}
.border-pink{border-color: var(--bs-pink)!important}

.text-blue{color: var(--blue)}
.text-light-blue{color: var(--light-blue)}
.text-orange{color: var(--bs-orange)}
.text-pink{color: var(--bs-pink)}

.bg-blue{background-color: var(--blue)}
.bg-light-blue{background-color: var(--light-blue)}
.bg-blue-light-25{background-color: rgba(var(--light-blue-rgb), 0.25)}
.bg-blue-light-15{background-color: rgba(var(--light-blue-rgb), 0.15)}
.bg-info-light-15{background-color: rgba(var(--bs-info-rgb), 0.15)}
.bg-warning-light-15{background-color: rgba(var(--bs-warning-rgb), 0.15)}
.bg-danger-light-15{background-color: rgba(var(--bs-danger-rgb), 0.15)}
.bg-primary-light-15{background-color: rgba(var(--bs-primary-rgb), 0.15)}
.bg-secondary-light-15{background-color: rgba(var(--bs-secondary-rgb), 0.15)}
.bg-success-light-15{background-color: rgba(var(--bs-success-rgb), 0.15)}
.bg-orange-light-15{background-color: rgba(var(--orange-rgb), 0.15)}
.bg-pink-light-15{background-color: rgba(var(--pink-rgb), 0.15)}

.objfit-cover{object-fit: cover}
.objpos-center{object-position: center}

a {
    color: var(--light-blue);
    &:hover{color: var(--blue)}
}
// .input-group-rounded{
//     >.btn, >.form-control, >.form-select, >.input-group-text {
//         padding: 0.5rem 1rem 0.55rem 1rem;
//         font-size: 1.18rem;
//         border-radius: 0.5rem;
//     }
// }
.btn{
    border-radius: 0;
}
.btn-group-lg>.btn, .btn-lg {
    padding: 0.5rem 1rem 0.55rem 1rem;
    font-size: 1.18rem;
}
.btn-primary{
    &.disabled, &:disabled{
        background-color: var(--blue);
        border-color: var(--blue);
    }
}
.btn-primary {
    color: #fff;
    background-color: var(--blue);
    border-color: var(--blue);
    &:hover{
        background-color: var(--light-blue);
        border-color: var(--light-blue);
    }
}
.btn-check:active+.btn-primary, .btn-check:checked+.btn-primary, 
.btn-primary.active, .btn-primary:active, .show>.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: var(--blue);
    border-color: var(--blue);
}
.btn-check:focus+.btn-primary, .btn-primary:focus {
    color: #fff;
    background-color:var(--blue);
    border-color: var(--blue);
    box-shadow: 0 0 0 0.25rem rgba(var(--light-blue-rgb), 0.50);
}
.form-control{
    border-radius: 0;
    &:hover{
        border-color: rgba(var(--light-blue-rgb), 0.75);
    }
    &:focus {
        color: var(--blue);
        background-color: #fff;
        border-color: rgba(var(--light-blue-rgb), 0.75);
        outline: 0;
        box-shadow: 0 0 0 0.25rem rgba(var(--light-blue-rgb), 0.35);
    }
}
.dropdown-item{
    &.active, &:active {
        background-color: var(--blue);
    }
}
.modal-backdrop, .offcanvas-backdrop{
    &.show {
        opacity: .70;
    }
}

.border-light-purple {
    border-color: #c6cbf9!important;
}
.bg-light-purple {
    background-color: rgb(240, 241, 251);
}


/* ===================================== */ 
/* Start | Inside Admin Dashabord Style */
.admin-header{
    top: 0;
    left: 250px;
    position: fixed;
    z-index: 100;
    width: calc(100% - 260px);
    height: 60px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: #edf7fc;
    background-color: #eee;
    // background-image: url(../images/body-bg.png);
    // box-shadow: 0 0 0.3rem rgba(0, 0, 0, 0.1);
    .admin-notification{
        width: 40px;
        height: 40px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        font-size: 24px;
        color: var(--light-blue);
        background-color: #fff;
        border-radius: 0px;
        position: relative;
        vertical-align: middle;
        transition: 350ms;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.075);
        cursor: pointer;
        outline: none;
        border: none;
        &:hover{
            color: #fff;
            background-color: var(--light-blue);
        }
        span{
            position: absolute;
            background-color: var(--blue);
            color: #fff;
            font-size: 12px;
            line-height: 1;
            font-weight: 500;
            padding: 3px 5px 4px 5px;
            border-radius: 6px;
            top: -5px;
            right: -4px;
        }
    }
    .admin-profile{
        display: flex;
        align-items: center;
        text-align: left;
        font-size: 15px;
        line-height: 1px;
        padding: 0 2px 0 2px;
        background: transparent;
        outline: none;
        border: none;
        height: 40px;
        font-weight: 600;
        color: var(--blue);
        letter-spacing: 0.5px;
        background-color: #fff;
        border-radius: 0px;
        transition: 350ms;
        &.show{
            background-color: var(--blue);
            color: #fff;
        }
        img{
            width: 36px;
            height: 36px;
            border-radius: 0px;
            display: block;
            object-fit: cover;
            object-position: center;
        }
    }
}
.admin-aside {
    top: 0;
    left: 0;
    position: fixed;
    z-index: 100;
    width: 260px;
    height: 100%;
    display: flex;
    flex-direction: column;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.10%);
    background-color: #fff;
    border-radius: 0;
    .admin-logobox {
        flex-grow: 0;
        padding: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        border-bottom: 1px solid rgba(var(--light-blue-rgb), 0.05);
        .admin-brandbox {
            text-decoration: none;
            img {
                width: 100%;
                max-width: 120px;
                display: block;
            }
        }
    }
    .admin-navbox {
        flex-grow: 1;
        padding: 15px 15px 80px 0px;
        overflow: hidden;
        overflow-y: auto;
        .accordion-item {
            border-radius: 0;
            border: none;
            background: transparent;
            h6{
                text-transform: uppercase;
                color: var(--light-blue);
                font-size: 12px;
                font-weight: 600;
                letter-spacing: 0.5px;
                padding: 35px 10px 10px 15px;
                margin: 0;                
                position: relative;
            }
            .main-link {
                display: flex;
                text-decoration: none;
                align-items: center;
                white-space: nowrap;
                padding: 12px 15px;
                margin-bottom: 4px;
                border-radius: 0;
                font-size: 16px;
                font-weight: 400;
                color: var(--blue);
                position: relative;
                transition: 350ms;
                &::before {
                    content: '';
                    position: absolute;
                    width: 7px;
                    height: 7px;
                    border-top: 2px solid currentColor;
                    border-right: 2px solid currentColor;
                    top: calc(50% - 3px);
                    right: 15px;
                    transform: rotate(45deg);
                    opacity: 0;
                    transition: 300ms;
                }
                &[aria-expanded]::before {
                    opacity: 0.75;
                }
                &[aria-expanded="true"]::before {
                    transform: rotate(135deg);
                }
                &:hover, &[data-currentpage="true"] {
                    background: rgba(var(--light-blue-rgb), 0.25);
                }
                &.active,
                &[aria-expanded="true"] {
                    background-color: var(--light-blue);
                    color: #fff;
                }
                [class*="icon"] {
                    width: 24px;
                    min-width: 24px;
                    height: 24px;
                    font-size: 24px;
                    line-height: 1;
                    margin-right: 14px;
                    opacity: 0.75;
                }
            }
        }
        .accordion-collapse {
            padding-left: 0px;
            font-size: 15px;
            .sub-link {
                display: block;
                text-decoration: none;
                padding: 8px 10px 8px 54px;
                margin-bottom: 2px;
                color: var(--blue);
                font-weight: 400;
                position: relative;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                transition: 350ms;
                &:last-child{
                    margin-bottom: 20px;
                }
                &::before{
                    content: '';
                    display: block;
                    top: calc(50% - 4.5px);
                    left: 22px;
                    position: absolute;
                    width: 10px;
                    height: 10px;
                    border: 2px solid rgba(var(--blue-rgb),0.6);
                }
                &:hover {
                    background: rgba(var(--light-blue-rgb), 0.15);
                }
                &.active,
                &:active {
                    background:  rgba(var(--light-blue-rgb), 0.25);
                    &::before{
                        background-color: rgba(var(--blue-rgb),0.30);
                        border: 2px solid rgba(var(--blue-rgb),0.75);
                    }
                }
            }
        }
    }
    .admin-copyrightsbox {
        flex-grow: 0;
        border-top: 1px solid rgba(var(--light-blue-rgb), 0.1);
        padding: 5px 10px 5px 10px;
        font-size: 11px;
        font-weight: 500;
        color: var(--blue);
        letter-spacing: 0.1px;
    }
}
.admin-wrapper {
    padding-top: 60px;
    padding-bottom: 30px;
    padding-left: 290px;
    padding-right: 30px;
    background: transparent;
    min-height: 100vh;
}
.page-heading{
    color: var(--blue);
    position: fixed;
    top: 18px;
    left: 300px;
    z-index: 112;
    line-height: 1;
    padding-bottom: 8px;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0.1px;
    small{
        font-size: 65%;
        font-weight: 400;
        padding-left: 5px;
    }
}

.table-responsive{
    position: relative;
    &::before{
        content: '';
        display: inline-block;
        width: 2rem;
        height: 2rem;
        vertical-align: -0.125em;
        border: 0.25em solid var(--blue);
        border-right-color: transparent;
        border-radius: 50%;
        animation: .75s linear infinite spinner-border;
        position: absolute;
        left: calc(50% - 1rem);
        top:80px;
        opacity: 0;
        visibility: hidden;
        z-index: 10;
    }
    &[data-tablespinner="true"]{
        &::before{
            opacity: 1;
            visibility: visible;
        }
        table{
            pointer-events: none;
            opacity: 0.4;
        }
    }
}
.table-common{
    padding: 1px;
    width: 100%;
    border-collapse: separate;
    border-spacing: 0px 6px;
    transition: 300ms;
    thead{
        tr{
            border-radius: 0 ;
            box-shadow: 0 0 0 1px rgba(var(--light-blue-rgb),0.2);
            th {
                font-size: 13.5px;
                letter-spacing: 0.25px;
                border: none;
                padding: 8px 14px;
                font-weight: 700;
                color: var(--blue);
                background-color: rgba(var(--light-blue-rgb), 0.15);
                margin-bottom: 4px;
                text-transform: uppercase;
                position: relative;
                &::before, &::after{
                    content: '';
                    position: absolute;
                    top: 50%;
                    width: 0;
                    height: 0;
                    right: 4px;
                    margin-top: -7px;
                    border-left: 4px solid transparent;
                    border-right: 4px solid transparent;
                    border-bottom: 7px solid currentColor;
                    opacity: 0;
                }
                &::after{
                    margin-top: 2px;
                    border-bottom: 4px solid transparent;
                    border-top: 6px solid currentColor;
                }
                &[data-orderby]{
                    padding-right: 20px;
                    cursor: pointer;
                    &::before, &::after{
                        opacity: 0.25;
                    }
                }
                &.active{
                    &[data-orderby="asc"]{
                        &::before{
                            opacity: 0.75;
                        }
                    }
                    &[data-orderby="desc"]{
                        &::after{
                            opacity: 0.75;
                        }
                    }
                }
            }
        }
    }
    tbody{
        tr {
            &:hover {
                box-shadow: 0 0 1px 1px rgba(var(--light-blue-rgb),0.4);
                th, td{
                    color: #222;
                }
            }
            th, td {
                color: #444;
                border: none;
                padding: 8px 14px;
                font-size: 14.5px;
                background-color: #fff;
                transition: 300ms;
            }
        }
    }
}
.table-layout-fixed{
    table-layout: fixed;
}
.form-search{
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%236c757d' viewBox='0 0 512 512'%3E%3Cpath d='M508.5 468.9L387.1 347.5c-2.3-2.3-5.3-3.5-8.5-3.5h-13.2c31.5-36.5 50.6-84 50.6-136C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c52 0 99.5-19.1 136-50.6v13.2c0 3.2 1.3 6.2 3.5 8.5l121.4 121.4c4.7 4.7 12.3 4.7 17 0l22.6-22.6c4.7-4.7 4.7-12.3 0-17zM208 368c-88.4 0-160-71.6-160-160S119.6 48 208 48s160 71.6 160 160-71.6 160-160 160z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 14px 14px;
    background-position: 12px 11px;
    padding-left: 35px;
}
.btn-pageof{
    border: 1px solid #ced4da;
}
.pagination{
    .page-item{
        &.active{
            pointer-events: none;
            .page-link {
                &, &:hover{
                    color: #fff;
                    background-color: var(--blue);
                    border-color: #dee2e6;
                }
            }
        }
        &.disabled{
            cursor: not-allowed;
        }
        .page-link {
            color: #444;
            min-width: 34px;
            height: 34px;
            text-align: center;
            padding: 0.23rem 0.25rem 0.28rem 0.25rem;
            font-size: 14px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            border-radius: 0;
            &:hover{
                color: #fff;
                background-color: var(--light-blue);
            }
        }
        &:first-child{
            .page-link {
                padding: 0.22rem 0.85rem 0.28rem 0.85rem;
            }
        }
        &:last-child{
            .page-link {
                padding: 0.22rem  0.85rem 0.28rem  0.85rem;
            }
        }        
    }
}


.toast-end{
    .toast{
        letter-spacing: 0.4px;
        overflow: hidden;
        font-size: 15.5px;
        &.show {
            opacity: 1;
            animation: toastanim 0.350s linear forwards;
        }
        &.hide {
            display: block;
            opacity: 1;
            animation: toastanimreverse 0.350s linear forwards;
        }
        .bg-warning, .bg-light{
            .toast-body{
                color: #000 !important;
            }
            .btn-close{
                background-color: rgba(0,0,0,0.05);
                filter: invert(0);
                &:hover{
                    filter: invert(1);
                    background-color: rgba(4, 255, 255, 0.6);
                }
            }
        }
        .btn-close{
            background-color: rgba(255,255,255,0.05);
            filter: invert(1);
            opacity: 1;
            transition: 300ms;
            width: 22px;
            min-width: 22px;
            height: 22px;
            max-height: 22px;
            &:hover{
                background-color: rgba(4, 255, 255, 0.6);
            }
        }
    }
}
@keyframes toastanim{
    from{transform: translateX(300px)}
    to{transform: translateX(0px)}
}
@keyframes toastanimreverse{
    from{transform: translateX(0px)}
    to{transform: translateX(600px)}
}

.overlay-onmobile{
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 100;
    background: rgba(0, 0, 0, 0.6);
    top: 0;
    left: 0;
    visibility: hidden;
    opacity: 0;
}

.sign-canvas{
    position: relative;
    canvas{
        width: 100%;
        min-height: 200px;
        height: 200px;
        position: relative;
        user-select: none;
        display: block;
        background-color: #ffffff;
    }
}




/*Start | Tablet + Mobile Style*/ 
@media (max-width: 1199.90px){
    .admin-header{
        left: 0px;
        z-index: 100;
        width: 100%;
        height: 70px;
        padding-left: 10px;
        padding-right: 10px;
    }
    .admin-aside{
        left: -280px;
        transition: 350ms;
    }
    .menu-active{
        .admin-aside{
            left: 0px;
        }
        .overlay-onmobile{
            visibility: visible;
            opacity: 1;
            transition: 350ms;
        }
    }
    .admin-wrapper {
        padding-top: 80px;
        padding-bottom: 30px;
        padding-left: 10px;
        padding-right: 10px;
    }
    .page-heading{
        left: 80px;
    }
   
}

@media (max-width: 767.90px){
    .page-heading{
        left: 0px;
        top: 0;
        z-index: 2;
        position: relative;
    }
}

@media (max-width: 574.90px){
    .admin-header{
        padding-left: 0px;
        padding-right: 0px;
    }
    .admin-wrapper{
        padding-left: 0px;
        padding-right: 0px;
    }
}
@media (max-width: 360px){
    .fw-ss-13{font-size: 13px}
    .fw-ss-14{font-size: 14px}
    .fw-ss-15{font-size: 15px}
    .entry-box{
        .input-group-lg{
            .form-control{
                font-size: 1rem;
            }
        }
        .btn-lg{
            font-size: 1rem;
        }
    }
}
/*End | Tablet + Mobile Style*/ 

#root{
    .admin-wrapper{
        .container-fluid{
            .row:not(:first-child){
                transform-origin: top;
                animation: pageanimation 350ms linear forwards;
            }
        }
    }
}
@keyframes pageanimation{
    from{transform: translate3d(-30px, 0, 0px); opacity: 0.1;}
    to{transform: translate3d(0, 0, 0px); opacity: 1;}
    // from{transform: scaleY(0.80); opacity: 0.5;}
    // to{transform: scaleY(1); opacity: 1;}
}