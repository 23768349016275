*,::after,::before{box-sizing:border-box}
.Toastify__toast--error{color:#842029!important;background-color:#f8d7da!important;border-color:#f5c2c7!important}
.Toastify__toast--warning{color:#664d03!important;background-color:#fff3cd!important;border-color:#ffecb5!important}
.Toastify__toast--success{color:#fff!important;background-color:#0d7a03!important;border-color:#0d7a03!important}
.Toastify__toast--info{color:#055160!important;background-color:#cff4fc!important;border-color:#b6effb!important}
.btn{border-radius:2px!important}
.bg-warning-light{background-color:#ffecb5!important}
.bg-warning{background-color:#ffca2c;color:#000}
.bg-danger{background-color:#f4082b}
.admin-aside{background:linear-gradient(90deg,#fff 8%,#fff 50%,#fff 87%)}
.admin-aside .admin-navbox .accordion-item:last-child{margin-bottom:20px}
.admin-aside .admin-navbox .accordion-item,.admin-aside .admin-navbox .accordion-collapse .sub-link{border-bottom:1px solid #fafafa}
.admin-aside .admin-navbox .accordion-item:last-child,.admin-aside .admin-navbox .accordion-collapse .sub-link:last-child{border-bottom:none!important}
.admin-aside .admin-navbox .accordion-collapse .sub-link:last-child{margin-bottom:auto}
.form-select-focus:focus{background-color:#fff;box-shadow:0 0 5px 1px #ff0000a3;color:#2a3285; border-width: 1px;}
.form-check-input:focus{border-color:#2a3285;box-shadow:0 0 5px 1px #ff0000a3; border-width: 1px;}
.shadow{box-shadow:0 .1rem .5rem rgba(0,0,0,.15)!important}
.minw-275{min-width:275px}
@media screen and (min-width: 480px) {
.admin-header{background:#fff;background:linear-gradient(90deg,rgba(255,255,255,1) 8%,#dedfe5 50%,rgba(255,255,255,1) 87%)}
}
.admin-notification{min-height:40px}
.admin-aside{z-index:115}
.invalid{width:100%;margin-top:.25rem;font-size:.775em;color:#dc3545}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle,.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle{margin-left:-115px!important}
::-webkit-input-placeholder{color:#999!important}
::-moz-placeholder{color:#999!important}
:-ms-input-placeholder{color:#999!important}
:-moz-placeholder{color:#999!important}
.maxw-200{max-width:200px}
.maxw-250{max-width:250px}
.maxw-300{max-width:300px}
.maxh-200{max-height:200px}
.text-primary{color:#2a3285!important}
.pointer{cursor:pointer!important}
.fs-8px{font-size:8px!important}
.fs-10px{font-size:10px!important}
.fs-11px{font-size:11px!important}
.fs-12px{font-size:12px!important}
.fs-13px{font-size:13px!important}
.fs-14px{font-size:14px!important}
.fs-15px{font-size:15px!important}
.fs-16px{font-size:16px!important}
.fs-17px{font-size:17px!important}
.fs-18px{font-size:18px!important}
.fs-19px{font-size:19px!important}
.fs-20px{font-size:20px!important}
.mt-10px{margin-top:10px!important}
.mt-15px{margin-top:15px!important}
.mt-20px{margin-top:20px!important}
.mt-25px{margin-top:25px!important}
.mt-30px{margin-top:30px!important}
.mt-40px{margin-top:40px!important}
.mt-50px{margin-top:50px!important}
.mt-60px{margin-top:60px!important}
.mt-70px{margin-top:70px!important}
.mt-80px{margin-top:80px!important}
.mt-90px{margin-top:90px!important}
.mt-100px{margin-top:100px!important}
.mb-10px{margin-bottom:10px!important}
.mb-20px{margin-bottom:20px!important}
.mb-30px{margin-bottom:30px!important}
.mb-40px{margin-bottom:40px!important}
.mb-50px{margin-bottom:50px!important}
.mb-60px{margin-bottom:60px!important}
.mb-80px{margin-bottom:80px!important}
.mb-100px{margin-bottom:100px!important}
input.arrow-hide::-webkit-outer-spin-button,input.arrow-hide::-webkit-inner-spin-button{-webkit-appearance:none;margin:0}
input[type=number].arrow-hide{-moz-appearance:textfield}
.light-gray{background-color:#ddd!important}
input.hide-indicator::-webkit-calendar-picker-indicator{opacity:0}
.bg-light-active{background:rgba(var(--light-blue-rgb),0.25)}
.table-records{border-collapse:collapse;width:100%}
.table-records th,.table-records td{border:1px solid #444;font-size:14px}
.td-padding-5 th,.td-padding-5 td{padding:5px 15px!important}
.td-padding-10 th,.td-padding-10 td{padding:10px 15px!important}
.page-heading2{color:#2a3285;line-height:1;padding-bottom:8px;font-size:20px;font-weight:700;letter-spacing:.1px}
.checkImg{width:25px}
.opacity-75{opacity:.75}
.opacity-50{opacity:.5}
.pointer-events-none,.btn-loading.active{pointer-events:none;opacity:.75}
.btn-loading .spinner-border,.btn-loading.active .icon{display:none}
.btn-loading.active .spinner-border{display:inline-block}
.btn-check:active+.btn-warning:focus,.btn-check:checked+.btn-warning:focus,.btn-warning.active:focus,.btn-warning:active:focus,.show>.btn-warning.dropdown-toggle:focus{box-shadow:none!important}
.site-details .nav-tabs .nav-link{margin-right:10px}
.nav-tabs .nav-link.active{border-color:#2a3285 #2a3285 #fff}
.nav-tabs .nav-link:hover{border-color:#2a3285 #2a3285 #eee}
.nav-tabs{border-bottom:1px solid #2a3285}
.img-60{max-width:60px!important;max-height:60px!important}
.img-80{max-width:80px!important;max-height:80px!important}
.img-100{max-width:100px!important;max-height:100px!important}
.select2-results__options strong.select2-results__group{color:#b7b9c1!important}
ul li.select2-results__option{border-top:1px solid #eee;font-size:14px!important}
.select2-container--bootstrap-5 .select2-selection{border-radius:0!important}
.site-details .accordion-button{padding:0 1rem;min-height:32px}
select.option-border [optgroup="label"]{color:grey;font-style:inherit;font-weight:300;text-shadow:none}
select.option-border optgroup option{border-bottom:1px solid #eee!important}
select.option-border optgroup option:last-child{border-bottom:none!important}
.react-datepicker__input-container .form-control.is-invalid,.react-datepicker__input-container .was-validated .form-control:invalid{background-position:right calc(2em + 0.1875rem) center}
.form-select.is-valid:not([multiple]):not([size]),.form-select.is-valid:not([multiple])[size="1"],.was-validated .form-select:valid:not([multiple]):not([size]),.was-validated .form-select:valid:not([multiple])[size="1"]{background-image:none}
.form-select.is-valid,.was-validated .form-select:valid{border-color:#ced4da}
form-select.is-valid:focus,.was-validated .form-select:valid:focus{color:var(--blue);background-color:#fff;border-color:rgba(var(--light-blue-rgb),0.75);outline:0;box-shadow:0 0 0 .25rem rgba(var(--light-blue-rgb),0.35)}
.modal-backdrop ~ .modal-backdrop{z-index:1057!important}
.modal ~ .modal{z-index:1060!important}
form#permissionForm [data-roleid="1"].permission-block .form-switch .form-check-input{opacity:.5!important;pointer-events:none!important}
.map-container,.google-map{aspect-ratio:21 / 7}
.map-container-model,.google-map{aspect-ratio:21 / 18}
@media only screen and (max-width: 768px) {
.site-details .nav-tabs,.site-details .nav-tabs .nav-link{width:100%}
.site-details .nav-tabs .nav-link{border-radius:1px;border:1px solid #2a3285;padding:5px 10px;margin:3px 0;background-color:#fff}
.site-details .nav-tabs .nav-link:last-child{margin-bottom:10px}
.site-details .nav-tabs .nav-link.active{background-color:#f9f7dde6;color:green}
.map-container,.google-map{aspect-ratio:21 / 16}
}