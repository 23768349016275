*, ::after, ::before {
  box-sizing: border-box;
}
/*Start | Datatable layout modified*/
.dataTable {
  padding: 1px;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0px 6px;
  transition: 300ms;
}
.dataTable tbody tr:hover {
  box-shadow: 0 0 1px 1px #eee!important;
  background-color: #eee!important;
  color: #000000;
}
.dataTable tbody tr:hover td{
  color: #000000!important;
}
.dataTables_wrapper .dataTables_length, .dataTables_wrapper .dataTables_filter {
  font-size: 0;
}

.dataTable.table>:not(:first-child) {
  border-top: 2px solid rgba(112, 118, 185, 0.35);
}

.dataTable tr th{
    font-size: 13.5px;
    letter-spacing: 0.25px;
    border: none;
    padding: 12px 14px!important;
    font-weight: 700!important;
    color: #2a3285!important;
    background-color: rgba(112, 118, 185, 0.15) !important;
    margin-bottom: 4px;
    text-transform: uppercase;
    position: relative;
}
.dataTable tr td{
  font-size: 14.5px!important;
  color: #6c757d!important;
  transition: 300ms;
}

.dataTable.table>:not(caption)>*>* {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px transparent;
}

.dataTables_wrapper .dataTables_length select, .dataTables_wrapper .dataTables_length input, .dataTables_wrapper .dataTables_filter select, .dataTables_wrapper .dataTables_filter input {
  display: block;
  width: 100%;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  outline: none;
  -webkit-transition: border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
  transition: border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
  padding: .25rem .5rem;
  font-size: .875rem;
  border-radius: .2rem;
}

.dataTables_wrapper .dataTables_length select:focus, .dataTables_wrapper .dataTables_length input:focus, .dataTables_wrapper .dataTables_filter select:focus, .dataTables_wrapper .dataTables_filter input:focus {
  color: #212529;
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
          box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.dataTables_wrapper .dataTables_filter {
  display: inline-block;
  width: 100%;
}

.dataTables_wrapper .dataTables_filter label {
  width: 100%;
  max-width: 280px;
}

.dataTables_wrapper .dataTables_filter label input {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%236c757d' viewBox='0 0 512 512'%3E%3Cpath d='M508.5 468.9L387.1 347.5c-2.3-2.3-5.3-3.5-8.5-3.5h-13.2c31.5-36.5 50.6-84 50.6-136C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c52 0 99.5-19.1 136-50.6v13.2c0 3.2 1.3 6.2 3.5 8.5l121.4 121.4c4.7 4.7 12.3 4.7 17 0l22.6-22.6c4.7-4.7 4.7-12.3 0-17zM208 368c-88.4 0-160-71.6-160-160S119.6 48 208 48s160 71.6 160 160-71.6 160-160 160z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 14px 14px;
  background-position: 10px 7px;
  padding-left: 33px;
}

.dataTables_wrapper .dataTables_length label {
  font-size: 14px;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.dataTables_wrapper .dataTables_length label select {
  margin: 0 6px;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right .22rem center;
  background-size: 14px 10px;
  padding-right: 20px;
  text-align-last: center;
  -moz-text-align-last: center;
}

.dataTables_wrapper .table {
  margin-bottom: 0;
  width: 100%;
}

.dataTables_wrapper .table thead .sorting {
  padding-right: 14px;
  position: relative;
}

.dataTables_wrapper .table thead .sorting::before, .dataTables_wrapper .table thead .sorting::after {
  content: '';
  position: absolute;
  top: 50%;
  width: 0;
  height: 0;
  right: 4px;
  margin-top: -6px;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 6px solid currentColor;
  opacity: 0.2;
}

.dataTables_wrapper .table thead .sorting::after {
  margin-top: 2px;
  border-bottom: 4px solid transparent;
  border-top: 6px solid currentColor;
}

.dataTables_wrapper .table thead .sorting.sorting_asc::before {
  opacity: 1;
  border-bottom-color: #fd7e14;;
}

.dataTables_wrapper .table thead .sorting.sorting_desc::after {
  opacity: 1;
  border-top-color: #fd7e14;;
}

.dataTables_wrapper .dataTables_info {
  font-size: 14px;
}

.dataTables_wrapper .dataTables_paginate .ellipsis {
  padding: 0 7px;
  height: 30px;
  min-width: 30px;
  display: inline-flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  letter-spacing: 1px;
  font-weight: bold;
  border: 1px solid transparent;
  margin-left: 2px;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
  height: 30px;
  min-width: 30px;
  text-decoration: none;
  display: inline-flex;
  border: 1px solid #dee2e6;
  margin-left: 2px;
  cursor: pointer;
  text-align: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 0.25rem;
  font-size: 14px;
  color: #212529;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.previous, .dataTables_wrapper .dataTables_paginate .paginate_button.next {
  font-weight: 500;
  padding: 0 7px;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.previous.disabled, .dataTables_wrapper .dataTables_paginate .paginate_button.next.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
  background-color: #0d6efd;;
  border-color: #0d6efd;;
  color: #fff;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  background-color: rgba(#0d6efd, 0.15);
  border-color: rgba(#0d6efd, 0.15);
}

.dataTables_wrapper .dataTables_processing {
  position: absolute;
  width: 100%;
  height: calc(100% - 51px);
  top: 51px;
  left: 0;
  background: rgba(255, 255, 255, 0.85);
  padding-top: 51px;
  text-align: center;
  color: var(--blue);
  font-weight: 600;
  letter-spacing: 1px;
}

.dataTables_wrapper .dataTables_empty {
  text-align: center !important;
  color: red;
  /* padding: 50px 10px !important; */
  opacity: 0.75;
}

@media (max-width: 991.90px){
  .dataTables_wrapper .dataTables_length,
  .dataTables_wrapper .dt-custom-filter{
    margin-bottom: 8px;
  }
  .dataTables_wrapper .dataTables_length{
    float: left;
  }
  .dataTables_wrapper .dataTables_filter label{
    max-width: 100%;
  }
  .dataTables_paginate{
    padding-top: 8px;
    padding-bottom: 8px;
  }
}
@media (max-width: 420px){
  .dataTables_wrapper .dataTables_paginate .paginate_button{
    font-size: 8px;
    margin-left: 1px;
  }
}

.dataTables_processing::before{
  content:'';
  position: absolute;
  border-radius: 20px;
  top: 2px;
  left: 0;
  background: linear-gradient(to right, #d80000, #f2f125, #2b3281, #000000)!important; 
  width: 0;
  height: 3px;
  z-index: 9999;
  box-shadow: 0 0px 0 1px #fff;
  animation: tableloadingBar 4s linear infinite;
}

@keyframes tableloadingBar {
  0% {width: 0%;}
  50% {width: 100%;}
  100% {width: 0%;}
}

.dt-buttons{
  position: relative;
}
.dt-button-collection{
  position: absolute;
  width: 200px;
  top: 40px;
  left: 0;
  margin-top: 0!important;
  background: #fff;
  box-shadow: 0 5px 14px #ccc;
  z-index: 10;
  padding: 15px;
  border-radius: 4px;
}
.dt-button-collection .dt-button{
  display: flex;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px 15px;
  margin-bottom: 5px;
  font-size: 14px;
}
.dt-button-collection .dt-button.active,
.export-btn:hover{
  background: var(--blue);
  color: #fff;
  border: 1px solid var(--blue);
}
.dt-button-collection .dt-button:last-child{
  margin-bottom: 0;
}
.dt-button-collection-title{
  color: var(--bs-primary);
  font-size: 13px;
  text-align: left;
  margin-bottom: 8px;
}
/*End | Datatable layout modified*/